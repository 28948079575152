import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { FormSubmission, ImageFilePreview, InputChange, RFC } from '../types/generalTypes';
import { Error, ErrorCodes, FetchRequest, NavStateKeys } from '../types/networkTypes';
import { GroupResponse } from '../types/groupTypes';
import { Entry as EntryType, Privacy } from '../types/entryTypes';
import { createInvitation, deleteGroupCoverPhoto, getGroup, getGroupCoverPhotoUrl } from '../api/group';
import { deleteEntry, editEntryPrivacy, getEntries } from '../api/entry';
import LoadingSpinner from '../components/LoadingSpinner';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import Modal from '../components/Modal';
import SubmitButton from '../components/SubmitButton';
import { copyToClipboard, validateEmail } from '../utils';
import ErrorMessage from '../components/ErrorMessage';
import { UserContext } from '../context/UserContextProvider';
import Entry from '../components/Entry';
import addDays from 'date-fns/addDays';
import modalReducer, { GroupDetailsModalScenarios as ModalScenarios, initialGroupDetailModalState } from '../reducers/groupDetailsModalReducer';
import { leaveGroup } from '../api/user';
import { AuthRoutes } from '../routes';
import { Theme } from '../theme';
import SuccessAlert from '../components/SuccessAlert';
import Alert from '../components/Alert';
import useNavBasedAlert from '../hooks/useNavBasedAlert';
import useOutsideClick from '../hooks/useOutsideClick';
import ToolTip from '../components/ToolTip';
import EntryPrivacySection from '../components/EntryPrivacySection';
import MoreEntriesLoader from '../components/MoreEntriesLoader';
import IconManager, { IconType } from '../components/IconManager';
import { LAST_GROUP_ID_VIEWED_BY_USER } from '../globals';
import { Analytics } from '../util/Analytics';
import GroupImageBanner from '../components/GroupImageBanner';
import isAuthed from '../hooks/isAuthed';
import GroupSidePanel from '../components/GroupSidePanel';

interface Props extends RouteComponentProps {
    id?: string;
}

const GroupDetails: RFC<Props> = ({ id }) => {
    isAuthed();
    const [groupData, setGroupData] = useState<FetchRequest<{ group?: GroupResponse; entries: EntryType[] }, Error>>({
        fetching: true,
        data: { group: undefined, entries: [] },
        error: null,
    });
    const { currentUser } = useContext(UserContext);
    const [modalScenario, dispatch] = useReducer(modalReducer, initialGroupDetailModalState);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [invitation, setInvitation] = useState<{ sending: boolean; success?: boolean }>({ sending: false });
    const [userEmailToInvite, setUserEmailToInvite] = useState<{ value: string; valid?: boolean | undefined }>({ value: '' });
    const userToInviteInputRef = useRef<HTMLInputElement>(null);
    const [userIsModerator, setUserIsModerator] = useState<boolean>(false);
    const [shareableLink, setShareableLink] = useState<string>();
    const [linkCopiedAlertVisible, setLinkCopiedAlertVisible] = useState<boolean>(false);
    const [inviteError, setInviteError] = useState<Error | null>(null);
    const [inviteAcceptedAlertVisible, setInviteAcceptedAlertVisible] = useState<boolean>(false);
    const [entryInFocus, setEntryInFocus] = useState<EntryType>();
    const [moderatorToolTipVisible, setModeratorToolTipVisible] = useState<boolean>(false);
    const moderatorToolTipRef = useRef<SVGSVGElement>(null);
    const [page, setPage] = useState<{ current: number; totalPages?: number }>({ current: 1 });
    const [loadingMoreEntries, setLoadingMoreEntries] = useState<boolean>(false);
    const [addingGroupBannerImage, setAddingGroupBannerImage] = useState<boolean>(false);
    const [uploadedCoverPhoto, setUploadedCoverPhoto] = useState<FetchRequest<ImageFilePreview | undefined, Error>>({
        fetching: false,
        data: undefined,
        error: null,
    });
    const [bannerImage, setBannerImage] = useState<string>();
    useOutsideClick({ ref: moderatorToolTipRef, callback: () => setModeratorToolTipVisible(false) });
    useNavBasedAlert({
        stateItem: NavStateKeys.INVITE_ACCEPTED,
        showAlert: () => setInviteAcceptedAlertVisible(true),
        dismissAlert: () => setInviteAcceptedAlertVisible(false),
    });

    useEffect(() => {
        if (!modalOpen) {
            dispatch({ type: ModalScenarios.RESET_MODAL_STATE });
            setInvitation({ sending: false });
            setUserEmailToInvite({ value: '' });
        } else {
            inviteError && setInviteError(null);
            userToInviteInputRef.current?.focus();
        }
    }, [modalOpen]);

    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    const [group, { entries, pages }] = await Promise.all([getGroup(id), getEntries({ groupId: id, page: page.current })]);
                    console.log(group);
                    sessionStorage.setItem(LAST_GROUP_ID_VIEWED_BY_USER, id);
                    setGroupData(prev => ({ ...prev, data: { group, entries: page.current === 1 ? entries : [...prev.data.entries, ...entries] } }));
                    setPage(prev => ({ ...prev, totalPages: pages }));
                } catch (error) {
                    error = error.json ? await error.json() : error;
                    setGroupData(prev => ({ ...prev, error }));
                }
                setGroupData(prev => ({ ...prev, fetching: false }));
            }
        })();
    }, [id, page.current]);

    useEffect(() => {
        if (groupData.data.group && currentUser) {
            const { members, coverPhoto } = groupData.data.group;
            setBannerImage(coverPhoto);
            setUserIsModerator(members.find(({ _id }) => _id === currentUser._id)?.moderator ?? false);
        }
    }, [groupData.data.group, currentUser]);

    const handleInvite = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        setInvitation({ sending: true });
        try {
            Analytics.Event.InviteSent.track();
            await createInvitation({ groupId: groupData.data.group!._id, email: userEmailToInvite.value });
            setInvitation({ sending: false, success: true });
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setInviteError(error);
            setInvitation({ sending: false, success: false });
            setModalOpen(false);
        }
    };

    const handleLeaveGroup = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            Analytics.Event.LeaveGroup.track();
            await leaveGroup(groupData.data.group!._id);
            await navigate(AuthRoutes.GROUP_LIST);
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setGroupData(prev => ({ ...prev, error }));
        }
    };

    const getShareableLink = async (): Promise<void> => {
        try {
            Analytics.Event.MultiUserInviteCreated.track();
            const link = await createInvitation({ groupId: groupData.data.group!._id });
            link && setShareableLink(link);
        } catch (error) {
            console.log(error);
        }
    };

    const updateGroupInLocalState = ({ group, removedMemberId }: { group: GroupResponse; removedMemberId?: string }) => {
        setGroupData(prev => ({
            ...prev,
            data: removedMemberId
                ? { entries: prev.data.entries.filter(({ author }) => author._id !== removedMemberId), group }
                : { ...prev.data, group },
        }));
    };

    const openModal = (type: ModalScenarios) => dispatch({ type, callback: () => setModalOpen(true) });

    const handleEntryDelete = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (entryInFocus) {
            try {
                Analytics.Event.EntryDeleted.track();
                await deleteEntry(entryInFocus._id);
                setGroupData(prev => ({
                    ...prev,
                    data: { ...prev.data, entries: prev.data.entries.filter(({ _id }) => _id !== entryInFocus._id) },
                }));
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
                setGroupData(prev => ({ ...prev, error }));
            }
        }
    };

    const asyncSetEntryInFocus = (entry: EntryType): Promise<EntryType> => {
        return new Promise(resolve => {
            setEntryInFocus(entry);
            resolve(entry);
        });
    };

    const updateEntryInLocalState = (updatedEntry: EntryType) => {
        setGroupData(prev => ({
            ...prev,
            data: {
                ...prev.data,
                entries: prev.data.entries.map(entry => (entry._id === updatedEntry._id ? updatedEntry : entry)),
            },
        }));
    };

    const handleEntryPrivacyInput = (e: InputChange) => {
        e.persist();
        setEntryInFocus(prev => (prev ? { ...prev, privacy: e.target.value as Privacy } : prev));
    };

    const handleUpdateEntryPrivacy = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (entryInFocus) {
            try {
                Analytics.Event.EntryPrivacyUpdated.track();
                const updatedEntry = await editEntryPrivacy({ entryId: entryInFocus._id, privacy: entryInFocus.privacy });
                updateEntryInLocalState(updatedEntry);
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
                setGroupData(prev => ({ ...prev, error }));
            }
        }
    };

    const handleGroupImageUpload = async (): Promise<void> => {
        if (uploadedCoverPhoto.data) {
            try {
                setUploadedCoverPhoto(prev => ({ ...prev, fetching: true }));
                const groupPhotoUploadUrl = await getGroupCoverPhotoUrl(groupData.data.group!._id);
                if (groupPhotoUploadUrl) {
                    await fetch(groupPhotoUploadUrl, {
                        method: 'PUT',
                        body: uploadedCoverPhoto.data,
                        headers: { 'Content-Type': 'image/jpeg' },
                    });
                    const groupPhotoImage = groupPhotoUploadUrl.split('?')[0];
                    setGroupData(prev => ({ ...prev, data: { ...prev.data, group: { ...prev.data.group!, coverPhoto: groupPhotoImage } } }));
                }
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
                setUploadedCoverPhoto(prev => ({ ...prev, error }));
            }
            setUploadedCoverPhoto(prev => ({ ...prev, fetching: false }));
            setAddingGroupBannerImage(false);
        }
    };

    const handleGroupImageDeletion = async (): Promise<void> => {
        if (groupData.data.group?._id) {
            try {
                setUploadedCoverPhoto(prev => ({ ...prev, fetching: true }));
                const updatedGroup = await deleteGroupCoverPhoto(groupData.data.group._id);
                setGroupData(prev => ({ ...prev, data: { ...prev.data!, group: updatedGroup } }));
                setBannerImage(undefined);
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
                setUploadedCoverPhoto(prev => ({ ...prev, error }));
            }
            setUploadedCoverPhoto(prev => ({ ...prev, fetching: false }));
            setAddingGroupBannerImage(false);
        }
    };

    const loadMoreEntries = () => {
        Analytics.Event.EntryMoreLoaded.track();
        setLoadingMoreEntries(true);
        setPage(prev => ({ ...prev, current: prev.current + 1 }));
        setTimeout(() => {
            setLoadingMoreEntries(false);
        }, 1000);
    };

    useEffect(() => {
        if (uploadedCoverPhoto.data) {
            setBannerImage(uploadedCoverPhoto.data.preview);
        }
    }, [uploadedCoverPhoto]);

    const cancelChangingImage = () => {
        setBannerImage(groupData.data.group?.coverPhoto);
        setAddingGroupBannerImage(false);
    };

    return (
        <>
            {(addingGroupBannerImage || bannerImage) && (
                <div
                    className={`mx-auto md:ml-0 px-5 py-3 ${
                        addingGroupBannerImage ? 'text-center bg-gray-100 rounded-md my-3 border border-gray-200' : ''
                    } relative`}
                    style={{ width: '95%' }}
                >
                    <GroupImageBanner
                        loading={uploadedCoverPhoto.fetching}
                        setUploadedCoverPhoto={imageFile => setUploadedCoverPhoto(prev => ({ ...prev, data: imageFile }))}
                        groupName={groupData.data.group?.name}
                        uploadedGroupImageUrl={bannerImage}
                        isAddingImageWithoutPrevImage={addingGroupBannerImage && !groupData.data.group?.coverPhoto}
                        isAddingImageWithPrevImage={addingGroupBannerImage && !!groupData.data.group?.coverPhoto}
                        handleUpload={handleGroupImageUpload}
                        clearBannerImage={() => setBannerImage(undefined)}
                        cancelChangingImage={cancelChangingImage}
                        deleteBannerImage={handleGroupImageDeletion}
                    />
                </div>
            )}
            <div className="w-full flex flex-row justify-between items-center px-5 relative">
                <div className="w-full md:w-3/4">
                    {groupData.fetching ? (
                        <LoadingSpinner type="page" />
                    ) : (
                        <>
                            {!bannerImage && <h2 className="text-3xl text-blue-900 my-2">{groupData.data.group?.name}</h2>}
                            {inviteAcceptedAlertVisible && (
                                <Alert
                                    message={`You've accepted an invitation to join ${groupData.data.group?.name}`}
                                    close={() => setInviteAcceptedAlertVisible(false)}
                                    type="success"
                                    className="w-2/3 top-auto"
                                />
                            )}
                            <div className="flex flex-row ml-2 justify-center md:justify-start items-center">
                                <button
                                    className="mr-2 text-sm bg-blue-500 hover:bg-blue-700 flex flex-row items-center text-white rounded-md py-1 px-2 font-bold mb-2"
                                    onClick={() => openModal(ModalScenarios.SHARING_GROUP_INVITE)}
                                >
                                    <IconManager type={IconType.INVITE} size={20} className="mr-1" stroke="#fff" />
                                    Invite
                                </button>
                                <Link to={AuthRoutes.GROUP_LIST + `/${groupData.data.group?._id}/members`}>
                                    <button
                                        className={`mr-2 block md:hidden text-sm bg-${Theme.darkBlue} hover:bg-blue-800 flex flex-row items-center text-white rounded-md py-1 px-2 font-bold mb-2`}
                                    >
                                        {groupData.data.group?.members.length}
                                        <IconManager type={IconType.GROUPS} size={20} className="ml-1 mr-2" stroke="#fff" />
                                        <span className="mr-1">View</span>
                                        Members
                                    </button>
                                </Link>
                                <button
                                    className="text-sm bg-red-500 hover:bg-red-700 flex flex-row items-center text-white rounded-md py-1 px-2 font-bold mb-2"
                                    onClick={() => openModal(ModalScenarios.LEAVING_GROUP)}
                                >
                                    <IconManager type={IconType.LEAVE_GROUP} size={20} className="mr-1" stroke="#fff" />
                                    Leave
                                </button>
                                <span className="hidden md:block text-sm text-gray-600 flex flex-row items-center py-1 px-2">
                                    <IconManager type={IconType.GROUPS} size={20} className="mx-1 inline mb-1" stroke="#718096" />
                                    {groupData.data.group?.members.length} members
                                </span>
                            </div>
                            <button
                                className={`text-${addingGroupBannerImage ? 'gray' : 'blue'}-500 hover:text-${
                                    addingGroupBannerImage ? 'gray' : 'blue'
                                }-700 mx-auto md:ml-3 md:mr-0 p-1 mt-1 text-xs mb-2 flex flex-row items-center focus:outline-none`}
                                onClick={
                                    addingGroupBannerImage
                                        ? cancelChangingImage
                                        : () => {
                                              setBannerImage(undefined);
                                              setAddingGroupBannerImage(true);
                                          }
                                }
                            >
                                <IconManager type={IconType.IMAGE} className="mr-1" />
                                {addingGroupBannerImage
                                    ? 'Cancel group banner changes'
                                    : groupData.data.group?.coverPhoto
                                    ? 'Change group banner'
                                    : 'Add group banner'}
                            </button>
                            {userIsModerator && (
                                <div className="w-full flex flex-row justify-start mb-5 relative">
                                    <span className="text-green-500 flex flex-row items-center mx-auto md:ml-4">
                                        <IconManager type={IconType.GROUP_MODERATOR} className="mr-1" stroke="#4EBC7A" />
                                        You are a moderator for this group{' '}
                                        <IconManager
                                            ref={moderatorToolTipRef}
                                            type={IconType.INFO}
                                            size={16}
                                            stroke="#718096"
                                            className="ml-1 cursor-pointer"
                                            onMouseEnter={() => setModeratorToolTipVisible(true)}
                                            onMouseLeave={() => setModeratorToolTipVisible(false)}
                                            onClick={() => setModeratorToolTipVisible(true)}
                                        />
                                        <ToolTip
                                            verbiage="Moderators can add & remove members, make other members moderators & hide entries"
                                            visible={moderatorToolTipVisible}
                                        />
                                    </span>
                                </div>
                            )}
                            {groupData.error && <ErrorMessage code={groupData.error?.code} className="my-2" />}
                            <div className="max-w-3xl">
                                {inviteError?.code === ErrorCodes.DATA_VALIDATION && (
                                    <p className="text-red-500 block mb-2 text-center">
                                        It looks like this user is already a member of this group. Try inviting someone else!
                                    </p>
                                )}
                                <ul className="list-disc max-w-3xl">
                                    {!groupData.data.entries.length ? (
                                        <div className="text-center md:w-1/2 text-gray-500 mt-5">
                                            It looks like this group doesn't have any entries.
                                            <Link to={AuthRoutes.CREATE_ENTRY} className="text-blue-500 block">
                                                Create one
                                            </Link>
                                        </div>
                                    ) : (
                                        groupData.data.entries.map(entry => (
                                            <Entry
                                                userIsModerator={userIsModerator}
                                                groupId={groupData.data.group?._id}
                                                entry={entry}
                                                key={entry._id}
                                                initiateDelete={async () => {
                                                    await asyncSetEntryInFocus(entry);
                                                    openModal(ModalScenarios.DELETING_OWN_ENTRY);
                                                }}
                                                initiateEdit={async () => {
                                                    await asyncSetEntryInFocus(entry);
                                                    openModal(ModalScenarios.EDITING_ENTRY_PRIVACY);
                                                }}
                                                updateEntryInLocalState={updateEntryInLocalState}
                                                shouldLinkToMemberProfile={true}
                                            />
                                        ))
                                    )}
                                </ul>
                                <MoreEntriesLoader
                                    initialPageFetching={groupData.fetching}
                                    loadingMoreEntries={loadingMoreEntries}
                                    loadMoreEntries={loadMoreEntries}
                                    activePage={page.current}
                                    totalPages={page.totalPages}
                                />
                            </div>
                            {modalOpen && (
                                <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
                                    <div className="w-full px-5 py-3">
                                        {(modalScenario[ModalScenarios.LEAVING_GROUP] || modalScenario[ModalScenarios.DELETING_OWN_ENTRY]) && (
                                            <form
                                                onSubmit={async e => {
                                                    modalScenario[ModalScenarios.LEAVING_GROUP]
                                                        ? await handleLeaveGroup(e)
                                                        : await handleEntryDelete(e);
                                                    setModalOpen(false);
                                                }}
                                            >
                                                <h3 className="font-semibold text-center text-gray-700">
                                                    {modalScenario[ModalScenarios.LEAVING_GROUP] ? (
                                                        <>
                                                            Are you sure you no longer want to be a member of <b>{groupData.data.group?.name}?</b>
                                                        </>
                                                    ) : (
                                                        <>Are you sure you want to delete this entry?</>
                                                    )}
                                                </h3>
                                                <div className="mt-2 mx-auto flex flex-row justify-center items-center w-full p-2">
                                                    <button
                                                        className="bg-transparent font-bold text-gray-500 mr-2 px-2 py-1"
                                                        onClick={() => setModalOpen(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white px-2 py-1"
                                                    >
                                                        Yes
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                        {modalScenario[ModalScenarios.SHARING_GROUP_INVITE] && invitation.success ? (
                                            <div className="flex flex-col justify-center">
                                                <SuccessAlert message="Success! An invite link has been sent to the provided email address" />
                                                <button
                                                    className="bg-transparent text-blue-500 hover:text-blue-700"
                                                    onClick={() => setModalOpen(false)}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        ) : (
                                            modalScenario[ModalScenarios.SHARING_GROUP_INVITE] && (
                                                <form onSubmit={handleInvite} className="p-2">
                                                    <label htmlFor="groupName" className="block">
                                                        <span className="text-gray-700 text-center text-lg mb-4 block">
                                                            What's your friend's email? We'll send them an email with a personal invite to join this
                                                            group.
                                                        </span>
                                                        <input
                                                            ref={userToInviteInputRef}
                                                            type="email"
                                                            className={`form-input mt-1 block w-full outline-none rounded border-${
                                                                userEmailToInvite.value.length >= 10 && userEmailToInvite.valid === false
                                                                    ? 'red'
                                                                    : 'gray'
                                                            }-200`}
                                                            value={userEmailToInvite.value}
                                                            onChange={e =>
                                                                setUserEmailToInvite({ value: e.target.value, valid: validateEmail(e.target.value) })
                                                            }
                                                        />
                                                        {userEmailToInvite.value.length >= 10 && userEmailToInvite.valid === false && (
                                                            <p className="text-sm text-red-500 italic">Please enter a valid email address</p>
                                                        )}
                                                    </label>
                                                    <SubmitButton
                                                        loading={invitation.sending}
                                                        disabled={!userEmailToInvite.value || userEmailToInvite.valid === false}
                                                        className="bg-green-500 hover:bg-green-700 mt-5 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                                                    >
                                                        INVITE
                                                    </SubmitButton>
                                                    <div className="flex flex-col justify-center text-center mt-2">
                                                        {shareableLink ? (
                                                            <div className="flex flex-wrap items-stretch w-full mb-4 mt-2 relative">
                                                                <input
                                                                    type="text"
                                                                    className="flex-shrink rounded-md flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-gray-200 rounded px-3 relative focus:border-blue focus:shadow"
                                                                    value={shareableLink}
                                                                    onChange={() => {}}
                                                                />
                                                                <div className="flex -mr-px">
                                                                    <span
                                                                        className="flex items-center cursor-pointer leading-normal bg-blue-500 rounded rounded-l-none border border-l-0 px-3 whitespace-no-wrap text-white text-sm"
                                                                        onClick={() => {
                                                                            copyToClipboard(shareableLink);
                                                                            setLinkCopiedAlertVisible(true);
                                                                            setTimeout(() => {
                                                                                setLinkCopiedAlertVisible(false);
                                                                            }, 2000);
                                                                        }}
                                                                    >
                                                                        <IconManager type={IconType.COPY_TO_CLIPBOARD} size={20} stroke="#fff" />
                                                                    </span>
                                                                    {linkCopiedAlertVisible && (
                                                                        <div
                                                                            className="flex items-center bg-blue-400 text-white rounded shadow-md w-full left-0 absolute text-sm font-bold px-4 py-3"
                                                                            style={{ top: '50px' }}
                                                                            role="alert"
                                                                        >
                                                                            <IconManager
                                                                                type={IconType.INFO}
                                                                                size={18}
                                                                                stroke="#fff"
                                                                                className="mr-1"
                                                                            />
                                                                            <p>Link copied to clipboard!</p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="text-blue-500 mx-auto mb-1 hover:text-blue-700 mt-5 font-bold py-2 px-4"
                                                                onClick={async e => {
                                                                    e.preventDefault();
                                                                    await getShareableLink();
                                                                }}
                                                            >
                                                                Get shareable invite link
                                                            </button>
                                                        )}
                                                        {shareableLink && (
                                                            <p className="text-gray-500 text-sm">
                                                                This link will allow <b>anyone</b> to join this group and will expire on{' '}
                                                                <span className="text-blue-500">{`${addDays(new Date(), 7).getMonth() + 1}/${addDays(
                                                                    new Date(),
                                                                    7
                                                                ).getDate()}/${addDays(new Date(), 7).getFullYear()}`}</span>
                                                            </p>
                                                        )}
                                                    </div>
                                                </form>
                                            )
                                        )}
                                        {modalScenario[ModalScenarios.EDITING_ENTRY_PRIVACY] && entryInFocus && (
                                            <form
                                                className="text-center"
                                                onSubmit={async e => {
                                                    await handleUpdateEntryPrivacy(e);
                                                    setModalOpen(false);
                                                }}
                                            >
                                                <EntryPrivacySection
                                                    privacySelection={entryInFocus.privacy}
                                                    handlePrivacySelection={handleEntryPrivacyInput}
                                                />
                                                <button
                                                    type="submit"
                                                    className="px-2 py-1 text-lg font-bold text-white bg-green-500 hover:bg-green-700 border-none rounded-md w-full md:w-2/3 mx-auto my-2"
                                                >
                                                    SAVE CHANGES
                                                </button>
                                            </form>
                                        )}
                                    </div>
                                </Modal>
                            )}
                        </>
                    )}
                </div>
                <GroupSidePanel
                    group={groupData.data.group}
                    userIsModerator={userIsModerator}
                    updateGroupInLocalState={updateGroupInLocalState}
                    loading={groupData.fetching}
                    topSpacing={bannerImage ? '7.6em' : '11.6em'}
                />
            </div>
        </>
    );
};

export default GroupDetails;
